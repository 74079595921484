
/* Set the font for titles and headers (Montserrat) */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-size: 300%; /* Increase font size by 10% */
}

nav {
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
}

/* Set the font for the body text (EB Garamond, serif) */
body, p {
  font-family: 'Open Sans', sans-serif;
    /* font-size: 120%; Increase font size by 10% */
}

body {
  /* max-width: 1200px; */
  margin: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.centered-text {
  text-align: center;
}

/* Disable responsive behavior for screens over 900px */
@media (min-width: 901px) {
  /* Reset responsive classes */
  .container, .container-fluid {
    max-width: 900px; /* Remove the maximum width */
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
  }
  
  /* Reset responsive columns */
  .col {
    flex-basis: auto;
  }
}